import React, { useState } from 'react';
import {
  BarChart as RechartsBarChart,
  Bar,
  XAxis,
  YAxis,
  CartesianGrid,
  ResponsiveContainer,
  Cell
} from 'recharts';
import LoadingAnimation from '../statistics/loadingAnimation';
import { ReactSVG } from 'react-svg';

export interface DataEntry {
  name: string;
  rating: number;
}

interface BarChartProps {
  data: DataEntry[];
  loading: boolean;
  chartType: 'frequency' |'rating';
  clickedBar: { name: string; chartType: 'frequency' | 'rating' } | null;
  domain?: {minValue: number; maxValue: number};
  ticks?: number[];
  onClick?: (entry: DataEntry, chartType: 'frequency' | 'rating') => void
}

const BarChart = ({
  data,
  loading,
  chartType,
  clickedBar,
  domain,
  ticks,
  onClick,
}: BarChartProps) => {
  const [hoveredBar, setHoveredBar] = useState<string | null>(null);

  const barHeight = 80;
  const chartHeight = data.length * barHeight;

  const handleBarClick = (entry: DataEntry) => {
    onClick && onClick(entry, chartType);
  };

  return (
    <div className='w-full h-full'>
      {loading ? (
        <LoadingAnimation />
      ) : data.length === 0 ? (
        <div className='flex flex-col justify-center ml-16 items-center font-semibold text-md text-theme-300'>
          <ReactSVG src='/noDataAvailable.svg' />
          Keine Daten verfügbar
        </div>
      ) : (
        <ResponsiveContainer height={chartHeight}>
          <RechartsBarChart
            data={data}
            layout='vertical'
            barSize={barHeight * 0.6}
          >
            <XAxis
              type='number'
              stroke='#6C856E'
              allowDecimals={false}
              domain={domain ? [domain.minValue, domain.maxValue] : undefined}
              ticks={ticks}
              tickLine={false}
              axisLine={false}
              style={{
                fontSize: '1.125rem',
                fontWeight: 'bold',
                letterSpacing: '0.03rem'
              }}
            />
            <YAxis
              type='category'
              dataKey='name'
              stroke='#6C856E'
              width={105}
              tickLine={false}
              tickFormatter={(label) => {
                const truncatedLabel =
                  label.length > 12 ? `${label.slice(0, 12)}...` : label;
                return truncatedLabel.replace(/\s/g, '\u00A0'); // Replace spaces with non-breaking spaces
              }}
              style={{
                fontSize: '0.8rem',
                fontWeight: 'bold',
                letterSpacing: '0.03rem'
              }}
            />
            <CartesianGrid
              vertical={true}
              horizontal={false}
              strokeWidth={1}
              stroke='#6C856E'
            />
            <Bar dataKey={'rating'} fill='#9b9b9b' radius={[7, 7, 7, 7]}>
              {data.map((entry, index) => (
                <Cell
                  key={index}
                  fill={
                    clickedBar?.name === entry.name && clickedBar.chartType === chartType
                      ? '#527055'
                      : hoveredBar === entry.name
                      ? '#6C856E'
                      : '#9b9b9b'
                  }
                  onClick={() => handleBarClick(entry)}
                  onMouseEnter={() => setHoveredBar(entry.name)}
                  onMouseLeave={() => setHoveredBar(null)}
                />
              ))}
            </Bar>
          </RechartsBarChart>
        </ResponsiveContainer>
      )}
    </div>
  );
};

export default BarChart;
