import { useEffect, useState } from 'react'
import { Incapability, StrippedTask, useIncapabilities, User, useTask } from '../../common'
import { XMarkIcon } from '@heroicons/react/24/solid'
import { feedbackToast } from '../components/toasts'

interface EditApprenticePopUpProps {
  onClose: () => void
  apprentice?: User
}

export default function EditApprenticePopup({
  onClose,
  apprentice,
}: EditApprenticePopUpProps) {
  const { getAllTasks } = useTask()
  const {getIncapabilities, createIncapabilities, deleteIncapabilities} = useIncapabilities()
  const [tasks, setTasks] = useState<StrippedTask[]>([])
  const [disabledTasks, setDisabledTasks] = useState<Incapability[]>([])

  useEffect(() => {
    getAllTasks()
      .then((fetchedTasks) => {
        setTasks(fetchedTasks)
      })
      .catch((error) => {
        feedbackToast({
          variant: 'error',
          message: 'Tasks konnten nicht geladen werden',
          toastId: 'TasksNotLoaded'
        })
        console.error('Error fetching tasks:', error)
      })

    getIncapabilities(apprentice!.userId)
      .then((fetchedIncapabilities) => {
        setDisabledTasks(fetchedIncapabilities);
      })
      .catch((error) => {
        feedbackToast({
          variant: 'error',
          message: 'Untauglichkeiten konnten nicht geladen werden',
          toastId: 'TasksNotLoaded'
        })
        console.error('Error fetching incapabilities:', error);
      });
  }, [])

  const toggleTask = (taskId: number) => {
    if (!apprentice?.userId) return;

    if (disabledTasks.some((id) => id.taskId === taskId)) {
      deleteIncapabilities(apprentice.userId, taskId)
        .then(() => {
          setDisabledTasks((prev) => prev.filter((id) => id.taskId !== taskId));
        })
        .catch((error) => console.error('Error enabling task:', error));
    } else {
      createIncapabilities(apprentice.userId, taskId)
        .then(() => {
          setDisabledTasks((prev) => [...prev, {userId: apprentice.userId, taskId}]);
        })
        .catch((error) => console.error('Error disabling task:', error));
    }
  };

  return (
<div className="container mx-auto">
  <div className="lg:mx-72 md:mx-32 sm:mx-24 mx-4 mt-8">
    <div className="bg-center bg-clip-border h-96 relative">
      <div className="flex justify-between items-center">
        <div className="text-theme-300 font-primary font-bold select-none text-2xl">
          Untauglichkeiten von {apprentice?.userName}
        </div>
        <XMarkIcon
          className="text-theme-300 h-9 w-9 outline-none rounded-full transition-all duration-200 ease-in hover:bg-theme-200/10"
          onClick={onClose}
        />
      </div>
      <div className="flex flex-wrap gap-2 mt-4">
        {tasks.map((task) => {
          const isDisabled = disabledTasks.some((disabledTask) => disabledTask.taskId === task.taskId);
          return (
            <div
              key={task.taskId}
              onClick={() => toggleTask(task.taskId)}
              className={`relative inline-flex items-center min-w-32 justify-center w-auto px-5 py-3 font-primary rounded-lg shadow-sm border-solid select-none cursor-pointer transition-all duration-200 group border
                ${isDisabled ? 'bg-transparent border-theme-200 text-theme-200 border-1 hover:bg-theme-300/10' : 'bg-theme-200 border-theme-200 text-white border hover:bg-theme-200/80'}
              `}
            >
              <span className={`transition-opacity duration-200 ${isDisabled ? 'line-through text-theme-200' : 'text-white'} opacity-100 group-hover:opacity-0`}>
                {task.name}
              </span>
              <span className={`absolute inset-0 flex items-center justify-center transition-opacity duration-200 opacity-0 group-hover:opacity-100 ${isDisabled ? 'text-theme-200' : 'text-white'}`}>
                {isDisabled ? 'Aktivieren' : 'Deaktivieren'}
              </span>
            </div>
          );
        })}
      </div>
    </div>
  </div>
</div>
  )
}
