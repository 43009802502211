import { useState } from 'react';
import dayjs, { Dayjs } from 'dayjs';
import 'tailwindcss/tailwind.css';
import PageButton from './pageButton';

interface DatePickerProps {
  onDateChange: (date: Dayjs) => void;
  onWeekChange: (week: number) => void;
}

const DatePicker = ({ onDateChange, onWeekChange }: DatePickerProps) => {
  const today = dayjs().startOf('day');
  const [currentWeek, setCurrentWeek] = useState(0);
  const [selectedDate, setSelectedDate] = useState(today);

  const getWeekDays = (weekOffset: number) => {
    const startOfWeek = today.add(weekOffset * 7, 'day').startOf('week');
    const days = [];
    for (let i = 0; i < 7; i++) {
      const day = startOfWeek.add(i, 'day');
      if (day.day() !== 0 && day.day() !== 6) {
        days.push(day);
      }
    }
    return days;
  };

  const weekDays = getWeekDays(currentWeek);

  const handlePrevWeek = () => {
    if (-2 < currentWeek) {
      const updatedWeek = currentWeek - 1;
      setCurrentWeek(updatedWeek);
      onWeekChange(updatedWeek);
  
      const weekday = getWeekDays(updatedWeek);
      const newDate = weekday[4];
      setSelectedDate(newDate);
      onDateChange(newDate);
    }
  };
  
  const handleNextWeek = () => {
    if (currentWeek < 2) {
      const updatedWeek = currentWeek + 1;
      setCurrentWeek(updatedWeek);
      onWeekChange(updatedWeek);
  
      const weekday = getWeekDays(updatedWeek);
      const newDate = weekday[0];
      setSelectedDate(newDate);
      onDateChange(newDate);
    }
  };

  return (
    <div className="flex items-center justify-center space-x-8">
      <PageButton
        direction='prev'
        onClick={handlePrevWeek}
        disabled={currentWeek <= -2}
      />
      {weekDays.map(day => (
        <div
          key={day.format('DD-MM-YYYY')}
          className="text-center cursor-pointer"
          onClick={() => {
            setSelectedDate(day);
            onDateChange(day);
          }}
        >
          <div className={`p-2 w-16 h-16 flex flex-col items-center justify-center rounded-full
            ${day.isSame(selectedDate, 'day') ? 'bg-theme-300 text-theme-50' : 'text-theme-300 transition-all duration-200 hover:bg-theme-200/10'}`}
          >
            <div className="text-lg font-secondary">{day.format('dd')}</div>
            <span className="text-2xl font-bold font-primary">{day.format('D')}</span>
          </div>
        </div>
      ))}
      <PageButton
        direction='next'
        onClick={handleNextWeek}
        disabled={2 <= currentWeek}
      />
    </div>
  );
};

export default DatePicker;
