import {
  LineChart,
  Line,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  ResponsiveContainer
} from 'recharts';
import LoadingAnimation from './loadingAnimation';
import { ReactSVG } from 'react-svg';

interface RatingLineChartProps {
  data: { date: string; rating: number | null }[];
  loading: boolean;
}

const RatingLineChart = ({ data, loading }: RatingLineChartProps) => {

  const isDataAvailable = (data: { date: string; rating: number | null }[]): boolean => {
    return data.some(entry => entry.rating !== null);
  };  

  return (
    <div className="w-full h-full">
      {loading ? (
          <LoadingAnimation />
      ) : !isDataAvailable(data) ? (
        <div className='flex flex-col justify-center ml-16 items-center font-semibold text-md text-theme-300'>
          <ReactSVG src='/noDataAvailable.svg' />
          Keine Daten verfügbar
        </div>
      ) : (
        <ResponsiveContainer width="100%" height={400}>
          <LineChart data={data} margin={{ top: 10, bottom: 40 }}>
            <CartesianGrid />
            <XAxis
              dataKey="date"
              tickLine={false}
              axisLine={false}
              style={{
                fontSize: '0.75rem',
                fontWeight: 'bold',
                letterSpacing: '0.03rem'
              }}
              angle={270}
              tick={{ textAnchor: 'end', dx: -5 }}
            />
            <YAxis
              domain={[0, 5]}
              tickLine={false}
              axisLine={false}
              ticks={[0, 1, 2, 3, 4, 5]}
              style={{
                fontSize: '1.125rem',
                fontWeight: 'bold',
                letterSpacing: '0.03rem'
              }}
            />
            <Tooltip />
            <Line
              type="monotone"
              dataKey="rating"
              stroke="#6C856E"
              strokeWidth={2}
              dot={{ r: 4 }}
              activeDot={{ r: 6 }}
            />
          </LineChart>
        </ResponsiveContainer>
      )}
    </div>
  );
};

export default RatingLineChart;
