import dayjs from 'dayjs';
import relativeTime from 'dayjs/plugin/relativeTime';
import {
  StarIcon as SolidStarIcon,
  UserCircleIcon
} from '@heroicons/react/24/solid';
import { StarIcon as OutlineStarIcon } from '@heroicons/react/24/outline';
import { ReactSVG } from 'react-svg';

export const FeedbackList = ({
  feedbackData
}: {
  feedbackData: {
    reviewerEmail: string;
    comment: string;
    date: string;
    rating: number;
  }[];
}) => {
  dayjs.extend(relativeTime);

  const formatDate = (dateString: string) => {
    const date = dayjs(dateString);
    const diffInWeeks = dayjs().diff(date, 'week');
    if (diffInWeeks === 0) return 'Diese Woche';
    if (diffInWeeks === 1) return 'Vor einer Woche';
    if (diffInWeeks < 4) return `Vor ${diffInWeeks} Wochen`;
    return date.fromNow();
  };

  const renderStars = (rating: number) =>
    Array.from({ length: 5 }, (_, i) =>
      i < rating ? (
        <SolidStarIcon key={i} className="h-5 w-5 text-orange-400" />
      ) : (
        <OutlineStarIcon key={i} className="h-5 w-5 text-orange-400" />
      )
    );

  return (
    <div>
      {feedbackData.length > 0 ? (
        <div className="flex flex-col space-y-4 mt-8 overflow-y-scroll transition-all duration-300 max-h-[23rem] pr-2">
          {feedbackData.map((feedback, index) => (
            <div
              key={index}
              className="flex bg-theme-300 text-theme-50 p-4 rounded-xl space-x-3"
            >
              <UserCircleIcon className="w-8 h-8" />
              <div className="flex-1">
                <div className="flex justify-between items-center">
                  <p className="font-semibold">{feedback.reviewerEmail}</p>
                  <span className="text-sm text-theme-100">
                    {formatDate(feedback.date)}
                  </span>
                </div>
                <div className="flex space-x-1 mt-1">
                  {renderStars(feedback.rating)}
                </div>
                <p className="text-md mt-2">{feedback.comment}</p>
              </div>
            </div>
          ))}
        </div>
      ) : (
        <div className="flex flex-col justify-center ml-16 items-center font-semibold text-md text-theme-300">
          <ReactSVG src="/noDataAvailable.svg" />
          Keine Daten verfügbar
        </div>
      )}
    </div>
  );
};
