import React, { useEffect, useState } from 'react'
import DefaultButton from '../components/button'
import { useApprentice } from '../../common/hooks/api'
import { User } from '../../common'
import ItemList from '../components/ItemList'
import { feedbackToast } from '../components/toasts'
import EditApprenticePopUp from './editApprenticePopup'

interface TaskOverviewProps {
  onNewApprenticeClick: () => void
  onEditApprenticeClick: () => void
  setIsEditPopupVisible: (visible: boolean) => void
  isEditPopupVisible: boolean
}

const ApprenticeOverview = ({
  onNewApprenticeClick,
  onEditApprenticeClick,
  setIsEditPopupVisible,
  isEditPopupVisible,
}: TaskOverviewProps) => {
  const [apprentices, setApprentices] = useState<User[]>([])
  const [selectedApprentice, setSelectedApprentice] = useState<User>()
  const { getApprentices, deleteApprentice } = useApprentice()

  useEffect(() => {
    getApprentices()
      .then((a) => setApprentices(a))
      .catch((error) => console.error('Error fetching apprentices:', error))
  }, [])

  const handleDelete = (index: number) => {
    const apprenticeEmail = apprentices[index].userEmail

    deleteApprentice(apprenticeEmail)
      .then(() => {
        setApprentices((prevApprentices) =>
          prevApprentices.filter((_, i) => i !== index)
        )
        feedbackToast({
          variant: 'success',
          message: 'Lernende wurde gelöscht.',
          ctaMessage: 'Rückgängig machen?',
          toastId: 'ApprenticeRemoved',
        })
      })
      .catch((error) => {
        console.error('Error deleting apprentice:', error)
        feedbackToast({
          variant: 'warn',
          message: 'Lernende konnte nicht gelöscht werden.',
          toastId: 'ApprenticeNotRemoved',
        })
      })
  }

  const handleEdit = (index: number) => {
    setSelectedApprentice(apprentices[index])
    setIsEditPopupVisible(true)
  }

  const handleClosePopup = () => {
    setIsEditPopupVisible(false)
  }

  return (
    <div className='flex'>
      {isEditPopupVisible ? (
        <EditApprenticePopUp
          onClose={handleClosePopup}
          apprentice={selectedApprentice}
        />
      ) : (
        <div className='container mx-auto'>
          <div className='lg:mx-72 md:mx-32 sm:mx-24 mx-4 mt-8'>
            <div className='bg-center bg-clip-border h-96 relative'>
              <div className='flex justify-between items-center'>
                <div className='text-theme-300 font-primary font-bold select-none text-2xl'>
                  Lernenden Übersicht
                </div>
                <DefaultButton
                  onClick={onNewApprenticeClick}
                  text='Neue Lernende hinzufügen'
                />
              </div>
              <div className='flex flex-wrap gap-2'>
                {apprentices.length === 0 ? (
                  <div
                    className='px-5 py-2 font-primary
                  text-theme-300/50 rounded-lg border-theme-300/50 border-dashed border select-none'
                  >
                    Es gibt noch keine Lernende!
                  </div>
                ) : (
                  <ItemList
                    list={apprentices}
                    onEdit={handleEdit}
                    onDelete={handleDelete}
                    emptyListNotice='Es gibt noch keine Lernende!'
                  />
                )}
              </div>
            </div>
          </div>
        </div>
      )}
    </div>
  )
}

export default ApprenticeOverview
