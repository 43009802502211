import React, { useEffect, useState } from 'react'
import dayjs, { Dayjs } from 'dayjs'
import weekOfYear from 'dayjs/plugin/weekOfYear'
import 'dayjs/locale/de';
import DatePicker from './datepicker'
import { ScheduledTask, useAbsence, useSchedule } from '../../common'
import { feedbackToast } from '../components/toasts'
import { InfoBox } from '../components/infoBox'
import PageButton from './pageButton'
import TaskCard from './scheduledTaskCard'
import { InfoBoxColors } from '../../common/enums/infoBoxColors'
dayjs.extend(weekOfYear);
dayjs.locale('de');

export const HomePage = () => {
  const [selectedDate, setSelectedDate] = useState<Dayjs>(
    dayjs().startOf('day')
  )
  const [selectedWeek, setSelectedWeek] = useState<number>(0)
  const [scheduledTasks, setScheduledTasks] = useState<ScheduledTask[]>([])
  const [currentPage, setCurrentPage] = useState(1)
  const scheduledTasksPerPage = 3
  const filteredScheduledTasks = scheduledTasks.filter(task => dayjs(task.date).isSame(selectedDate, 'day'))

  const totalPages = Math.ceil(
    filteredScheduledTasks.length / scheduledTasksPerPage
  )

  const { addAbsence } = useAbsence()
  const { getSchedule } = useSchedule()

  useEffect(() => {
    getSchedule().then(data => setScheduledTasks(data))
  }, [])

  useEffect(() => {
    setCurrentPage(1)
  }, [selectedDate, selectedWeek])

  const handleUpdateSingleAbsence = (userId: string, date: Date): void => {
    addAbsence(userId, date)
      .then(newScheduledTasks => {
        const oldScheduledTasks = scheduledTasks.filter(
          task => !dayjs(task.date).isSame(dayjs(date), 'day')
        )
        setScheduledTasks([...oldScheduledTasks, ...newScheduledTasks])
      })
      .then(() => {
        feedbackToast({
          variant: 'success',
          message: 'Abwesenheit wurde hinzugefügt.',
          ctaMessage: 'Rückgängig machen?',
          toastId: 'AbsenceAdded'
        })
      })
  }

  const getMonthOfTheWeek = () => {
    const startOfWeek = dayjs().add(selectedWeek, 'week').startOf('week');
    const endOfWeek = dayjs().add(selectedWeek, 'week').endOf('week');

    const startMonth = startOfWeek.format('MMMM');
    const endMonth = endOfWeek.format('MMMM');

    return startMonth !== endMonth ? `${startMonth}/${endMonth}` : startMonth;
  };

  const handlePrevPage = () => {
    if (currentPage > 1) {
      setCurrentPage(currentPage - 1)
    }
  }

  const handleNextPage = () => {
    if (currentPage < totalPages) {
      setCurrentPage(currentPage + 1)
    }
  }

  const currentTasks = filteredScheduledTasks.slice(
    (currentPage - 1) * scheduledTasksPerPage,
    currentPage * scheduledTasksPerPage
  )

  return (
    <div className='bg-theme-50 h-full'>
      <div className='text-center mb-2 text-3xl tracking-wide font-semibold font-primary text-theme-300'>
        {`${getMonthOfTheWeek()}, KW ${dayjs()
          .add(selectedWeek, 'week')
          .week()}`}
      </div>
      <DatePicker
        onDateChange={date => setSelectedDate(date)}
        onWeekChange={week => setSelectedWeek(week)}
      />
      <div>
        {filteredScheduledTasks.length === 0 ? (
          <InfoBox
            label='Heute sind keine Ämtli eingetragen.'
            bgColor={InfoBoxColors.THEME_300}
          />
        ) : (
          <div className='flex flex-col items-center space-y-3 mt-6'>
            <div className='flex items-center space-x-4'>
              <PageButton
                direction='prev'
                onClick={handlePrevPage}
                disabled={currentPage <= 1}
              />
              <div className='flex space-x-4'>
                {currentTasks.map(scheduledTask => (
                  <TaskCard
                    key={
                      new Date().getMilliseconds().toString() +
                      scheduledTask.taskId
                    }
                    scheduledTask={scheduledTask}
                    handleReplaceAbsenteeAssignment={handleUpdateSingleAbsence}
                  />
                ))}
                {Array.from({
                  length: scheduledTasksPerPage - currentTasks.length
                }).map((_, index) => (
                  <div key={index} className='w-80 h-96' />
                ))}
              </div>
              <PageButton
                direction='next'
                onClick={handleNextPage}
                disabled={currentPage >= totalPages}
              />
            </div>
            <div className='flex justify-center space-x-2'>
              {Array.from({ length: totalPages }, (_, index) => (
                <div
                  key={index}
                  className={`w-3 h-3 rounded-full ${
                    index + 1 === currentPage ? 'bg-theme-300' : 'bg-gray-300'
                  }`}
                />
              ))}
            </div>
          </div>
        )}
      </div>
    </div>
  )
}
